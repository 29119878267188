/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */


// react
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

// PRO
import MDBox from "/components/MDBox";

// MUI
const Card = dynamic(() => import("@mui/material/Card"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const Tooltip = dynamic(() => import("@mui/material/Tooltip"), { loading: () => null });

// MUI Icons
const OpenInNewRounded = dynamic(() => import('@mui/icons-material/OpenInNewRounded'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

// Parts
const ALLDAvatar = dynamic(() => import("/examples/ALLDAvatar"), { loading: () => null });

function ALLDCard({ row, pathPrefix, type }) {
  const [controller] = useMaterialUIController();
  return (
    <Grid item xs={12} md={6} lg={6} xl={4} mb={{ xs: 3, lg: 3 }} key={`${pathPrefix}-${row.id}`}>
      <Card>
        <MDBox position="relative" borderRadius="lg" mx={2} mt={-1} sx={{ cursor: "pointer" }}>
          <Link href={`${pathPrefix}/${row.path}`}>
            <MDBox>
              <MDBox>
                <MDBox sx={{ position: "absolute", top: 6, width: "100%", transform: "scale(1)", filter: "blur(12px)" }}>
                  <Image
                    src={row.teaserUrl}
                    alt=""
                    width={0}
                    height={0}
                    sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
                    style={{ objectFit: "cover", width: '100%', height: 'auto' }}
                  />
                </MDBox>
                <MDBox sx={{ position: "relative" }}>
                  <Image
                    src={row.teaserUrl}
                    alt={row.title}
                    shadow="md"
                    sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
                    width={0}
                    height={0}
                    style={{ zIndex: 1, borderRadius: 3, objectFit: "cover", width: '100%', height: 'auto' }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Link>
        </MDBox>
        <MDBox p={3}>
          {row.eyebrow !== undefined && (
            <MDTypography
              variant="caption"
              textTransform="uppercase"
              fontWeight="medium"
              sx={{ display: "block" }}
            >
              <span style={{ color: controller.darkMode ? row.darkColor : row.lightColor, filter: `brightness(${controller.darkMode ? 100 : 50}%)` }}>{row.eyebrow}</span>
            </MDTypography>
          )}
          <MDBox display="flex">
            <MDBox sx={{ display: "inline-block", flexGrow: 1, cursor: "pointer" }}>
              <Link href={`${pathPrefix}/${row.path}`}>
                <MDTypography
                  variant="h5"
                  component="h3"
                  textTransform="capitalize"
                  my={1}
                  sx={{ display: "inline-block", minHeight: 55 }}
                >
                  {row.title}
                </MDTypography>
              </Link>
            </MDBox>
            <Tooltip title={`Open ${type}`}>
              <span>
                <MDBox sx={{ cursor: "pointer" }}>
                  <Link href={`${pathPrefix}/${row.path}`}>
                    <MDButton
                      iconOnly
                      sx={{ backgroundColor: "initial !important", "&:focus": { boxShadow: "none !important" } }}
                      aria-label={`Open Project ${row.title}`}
                    >
                      <OpenInNewRounded color="info" sx={{ fontSize: "14px !important" }} />
                    </MDButton>
                  </Link>
                </MDBox>
              </span>
            </Tooltip>
          </MDBox>
          <MDTypography variant="body2" component="p" color="text" fontWeight="regular" sx={{ minHeight: 76.8 }}>
            {row.description}
          </MDTypography>
          <MDBox display="flex" alignItems="center" mt={3}>
            <ALLDAvatar />
            <MDBox pl={2} lineHeight={0}>
              <MDTypography component="h6" variant="button" fontWeight="medium" gutterBottom>
                Autumn Leaf
              </MDTypography>
              <MDTypography variant="caption" color="dark" fontWeight="regular">
                Posted on {row.created !== undefined && row.created !== null ? row.created : "20 April"}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

export default ALLDCard;
